<template>
  <div>
    <div class="p-4">
      <b-card class="round">
        <b-card-title class="d-flex justify-content-between">
          <h3>Resumo</h3>
          <div style="min-width:200px">
            <v-date-picker v-model="date" is-range>
              <template v-slot="{ inputValue, inputEvents }">
                <div class="flex justify-center items-center">
                  <input
                    :value="`${inputValue.start} - ${inputValue.end}`"
                    v-on="inputEvents.start"
                    class="form-control"
                  />
                </div>
              </template>
            </v-date-picker>
          </div>
        </b-card-title>
        <b-card-text>
          <p>Selecione uma opção no menu acima</p>
           
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    empresa: {
      type: Object,
    },
  },
  mounted() {},
  data() {
    return {
      loading: true,
      date: {
        start: new Date(),
        end: new Date(),
      },
    };
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
